import config from '../../../config';
import {
  createAsyncAction,
  createImprovedAsyncAction,
  createMailSubscriptionAction,
} from '../../actionCreators';
import * as types from '../../actionTypes';

export const setCustomerData = (customerData) => ({
  type: types.SET_CUSTOMER_DATA,
  payload: customerData,
});

export const fetchCustomerData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_CUSTOMER_DATA,
    path: 'customer.data',
  });
};

export const fetchTransactionAccountTransactions = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_TRANSACTION_ACCOUNT_TRANSACTIONS,
    path: 'customer.account.transactions',
  });
};

export const fetchAuthConfiguration = (base64Email) => {
  return createImprovedAsyncAction({
    type: types.FETCH_AUTH_CONFIGURATION,
    path: 'auth.configuration',
    dynamicPath: { base64Email },
    settings: { preventJwtRefresher: true },
  });
};

export const authenticateWithKeycloak = ({ username, password, KeycloakAuth, verificationId }) => {
  return {
    type: types.FETCH_KEYCLOAK_CREDENTIALS,
    promise: KeycloakAuth.directGrant({
      username,
      password,
      verificationId,
    }),
  };
};

export const postCustomerContactData = (data) => {
  return createAsyncAction(
    types.POST_CUSTOMER_CONTACT_DATA,
    'customer.contact_data.edit',
    'post',
    data,
  );
};

export const postCustomerContactDataConfirm = (data) => {
  return createAsyncAction(
    types.POST_CUSTOMER_ADDRESS_CONFIRM,
    'customer.contact_data.confirm',
    'post',
    data,
  );
};

export const postCustomerPassword = (data) => {
  return createAsyncAction(types.POST_CUSTOMER_PASSWORD, 'customer.password.edit', 'post', data);
};

export const postCustomerPasswordConfirm = (data) => {
  return createAsyncAction(
    types.POST_CUSTOMER_PASSWORD_CONFIRM,
    'customer.password.confirm',
    'post',
    data,
  );
};

export const postMtanBlock = () => {
  return createImprovedAsyncAction({
    method: 'post',
    path: 'customer.mtan.block',
    type: types.POST_CUSTOMER_MTAN_BLOCK,
  });
};

export const postCustomerMailingPreferences = (data) => {
  return createAsyncAction(
    types.POST_CUSTOMER_MAILING_PREFERENCES,
    'customer.mailing_preferences',
    'post',
    data,
  );
};

export const fetchMailSubscriptionData = (data, headers) => {
  return createMailSubscriptionAction({
    type: types.FETCH_MAIL_SUBSCRIPTION_DATA,
    path: 'customer.mail_consents',
    method: 'post',
    data,
    headers,
  });
};

export const subscribeToNewsletter = (data, headers) => {
  return createMailSubscriptionAction({
    type: types.SUBSCRIBE_TO_NEWSLETTER,
    path: 'customer.mail_consents',
    method: 'post',
    data,
    headers,
  });
};


export const fetchAccounts = (bacNumber) => {
  return createImprovedAsyncAction({
    type: types.FETCH_ACCOUNTS,
    path: 'tams.accounts',
    params: {
      params: { filter: `customerId eq ${bacNumber}` },
    },
    settings: {
      useJwt: true,
    },
  });
};

export const fetchTamsTransactionAccount = (accountId) => {
  return createImprovedAsyncAction({
    type: types.FETCH_TAMS_TRANSACTION_ACCOUNT,
    path: 'tams.transactionAccount',
    params: { params: { embed: 'balance' } },
    dynamicPath: { accountId },
    settings: {
      useJwt: true,
    },
  });
};

export const postCustomerLoginCredentials = (data) => {
  return createAsyncAction(types.POST_CUSTOMER_LOGIN_CREDENTIALS, 'customer.login', 'post', data, {
    preventJwtRefresher: true,
    resetSessionRefresher: true,
  });
};

export const postCustomerDocuments = (data) => {
  const endpoint = `customer.postDocuments.${data.type}`;

  return createAsyncAction(types.POST_CUSTOMER_DOCUMENTS, endpoint, 'upload', data.formData);
};

export const postMailboxData = (data) => {
  return createImprovedAsyncAction({
    type: types.POST_MAILBOX_DATA,
    path: 'mailbox.send',
    method: 'upload',
    params: data,
  });
};

export const postApplicationDocument = (data, type) => {
  return createImprovedAsyncAction({
    type: types.POST_APPLICATION_DOCUMENT,
    path: 'mailbox.postApplicationDocument',
    method: 'upload',
    params: data,
    dynamicPath: { type },
  });
};

export const fetchCustomerInvestments = () => {
  return createAsyncAction(types.FETCH_CUSTOMER_INVESTMENTS, 'customer.investments');
};

export const fetchCustomerDeposit = (id) => {
  return createImprovedAsyncAction({
    type: types.FETCH_CUSTOMER_DEPOSIT,
    path: 'customer.deposit',
    dynamicPath: { id },
  });
};

export const fetchDepositRevokeData = (depositNumber) => {
  return createImprovedAsyncAction({
    type: types.FETCH_DEPOSIT_REVOKE_DATA,
    path: 'order.getRevoke',
    dynamicPath: { depositNumber },
  });
};

export const postDepositRevokeData = (params) => {
  return createImprovedAsyncAction({
    type: types.POST_DEPOSIT_REVOKE_DATA,
    path: 'order.postRevoke',
    method: 'post',
    params,
  });
};

export const resetDepositRevokeData = () => ({
  type: types.RESET_DEPOSIT_REVOKE_DATA,
});

export const fetchAvailableCountries = () => {
  return createAsyncAction(types.FETCH_AVAILABLE_COUNTRIES, 'customer.contact_data.countries');
};

export const fetchMailBoxDocumentsData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_MAILBOX_DOCUMENTS_DATA,
    path: 'mailbox.documents',
  });
};

export const fetchMailBoxData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_MAILBOX_DATA,
    path: 'mailbox.all_folders',
  });
};

export const fetchMailBoxInboxData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_MAILBOX_INBOX_DATA,
    path: 'mailbox.inbox',
  });
};

export const fetchMailBoxSentData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_MAILBOX_SENT_DATA,
    path: 'mailbox.sent',
  });
};

export const fetchMailBoxCurrentMessageData = (id, folderName) => {
  return createImprovedAsyncAction({
    type: types.FETCH_MAILBOX_CURRENT_MESSAGE_DATA,
    path: 'mailbox.current_message',
    dynamicPath: { id, folderName },
  });
};

export const fetchMailboxUnreadMessagesCount = (bacNumber) => {
  return createImprovedAsyncAction({
    type: types.FETCH_MAILBOX_UNREAD_MESSAGES_COUNT,
    path: 'mailbox.unreadMessagesCount',
    settings: {
      useJwt: true,
      params: { customer_id: bacNumber },
    },
  });
};

export const changeCurrency = (currency) => ({
  type: types.CHANGE_CURRENCY,
  payload: currency,
});

export const postDisableAutoProlongation = (params) => {
  return createImprovedAsyncAction({
    type: types.POST_DISABLE_AUTO_PROLONGATION,
    path: 'deposits.autoProlongation.disable',
    params,
    method: 'post',
  });
};

export const fetchIdentificationInfo = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_IDENTIFICATION_INFO,
    path: 'customer.identification.get',
  });
};

export const postIdnowSendSms = (params) => {
  return createImprovedAsyncAction({
    type: types.POST_IDNOW_SEND_SMS,
    path: 'customer.identification.idnow.sendSms',
    method: 'post',
    params,
  });
};

export const postIdnowPhoneChange = (params) => {
  return createImprovedAsyncAction({
    type: types.POST_IDNOW_PHONE_CHANGE,
    path: 'customer.identification.idnow.phoneChange',
    method: 'post',
    params,
  });
};

export const postReferenceAccountInitial = (data) => {
  return createImprovedAsyncAction({
    type: types.POST_REFERENCE_ACCOUNT_INITIAL,
    path: 'customer.referenceAccount.initial',
    method: 'post',
    params: data,
  });
};

export const fetchNationalIdentifier = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_NATIONAL_IDENTIFIER,
    path: 'customer.nationalIdentifier',
  });
};

export const postNationalIdentifier = (data) => {
  return createImprovedAsyncAction({
    type: types.POST_NATIONAL_IDENTIFIER,
    path: 'customer.nationalIdentifier',
    method: 'post',
    params: data,
  });
};

export const fetchCustomerProductAccess = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_CUSTOMER_PRODUCT_ACCESS,
    path: 'customer.productAccess',
  });
};

export const fetchCustomerBranding = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_CUSTOMER_BRANDING,
    path: 'customer.branding',
  });
};

export const fetchCustomerValidityStatus = (bac) => {
  return createImprovedAsyncAction({
    type: types.FETCH_CUSTOMER_VALIDITY_STATUS,
    path: 'customer.validity',
    dynamicPath: { bac },
    settings: { useJwt: true },
  });
};

export const postBusinessCustomerValidityStatus = (bac, data) => {
  return createImprovedAsyncAction({
    type: types.POST_CUSTOMER_VALIDITY_STATUS,
    path: 'customer.validity',
    method: 'put',
    params: data,
    dynamicPath: { bac },
    settings: { useJwt: true },
  });
};

export const postCustomerBlockAccount = (data) => {
  return createImprovedAsyncAction({
    type: types.POST_CUSTOMER_BLOCK_ACCOUNT,
    path: 'customer.account.block',
    method: 'post',
    params: data,
  });
};

export const fetchCustomerDataForToken = (token) => {
  return createImprovedAsyncAction({
    type: types.FETCH_CUSTOMER_DATA_FOR_TOKEN,
    path: 'customer.password_initial.get',
    dynamicPath: { token },
    settings: { preventJwtRefresher: true },
  });
};

export const postPasswordInitial = (data) => {
  return createImprovedAsyncAction({
    type: types.POST_PASSWORD_INITIAL,
    path: 'customer.password_initial.post',
    method: 'post',
    params: data,
    settings: { preventJwtRefresher: true },
  });
};

/**
 * Fetch Documents specific to a deposit.
 * @param {string} deposit_id
 * @param {string} bac
 */
export const fetchDepositDocuments = (bac, deposit_id) => {
  return createImprovedAsyncAction({
    type: 'types.FETCH_BIPS_DEPOSIT_DOCUMENTS',
    path: 'documentsdepot',
    settings: {
      params: {
        customer_id: bac,
        deposit_id,
      },
      useJwt: true,
    },
  }).promise;
};

/**
 * Fetch a deposit document
 * @param {string} document_id
 */
export const fetchDepositDocumentByIdNative = async (document_id) => {
  const token = JSON.parse(window.localStorage.getItem('auth_token')).access_token;

  const headers = { Authorization: `Bearer ${token}` };

  const blob = await fetch(`${config.production.documentsdepot}/${document_id}?preview=true`, {
    headers,
  }).then((response) => response.blob());

  return blob;
};

export const toggleLoginOTPVerification = ({
  verificationId = null,
  guestToken = null,
  customerId = null,
  userId = null,
}) => {
  return {
    type: types.TOGGLE_LOGIN_OTP_VERIFICATION,
    payload: { verificationId, guestToken, customerId, userId },
  };
};

export const fetchCASCustomerData = (bacNumber, b2cDeprecateObs = false) => {
  return createImprovedAsyncAction({
    type: types.FETCH_CAS_CUSTOMER_DATA,
    path: 'cas.customerData',
    dynamicPath: { customer_id: bacNumber, b2cDeprecateObs },
    settings: {
      withCredentials: false,
      useJwt: true,
      params: {
        embed: ['all-persons', 'reference-accounts'],
      },
    },
  });
};

export const fetchKCICustomerKYCStatus = (bacNumber) => {
  return createImprovedAsyncAction({
    type: types.FETCH_KCI_CUSTOMER_KYC_STATUS,
    path: 'kci.customerKYCStatus',
    settings: {
      withCredentials: false,
      useJwt: true,
      params: { customer_id: bacNumber },
    },
  });
};

export const fetchKSSCustomerKYCStatus = (bacNumber) => {
  return createImprovedAsyncAction({
    type: types.FETCH_KSS_CUSTOMER_KYC_STATUS,
    path: 'kss.customerKYCStatus',
    settings: {
      useJwt: true,
      params: { customer_id: bacNumber },
    },
  });
};
