// portfolio action types
export const FETCH_PORTFOLIOS = 'FETCH_PORTFOLIOS';
export const FETCH_PORTFOLIO = 'FETCH_PORTFOLIO';
export const RESET_PORTFOLIOS = 'RESET_PORTFOLIOS';
export const SET_INVESTMENT_DATA = 'SET_INVESTMENT_DATA';

// savingsPlan action types
export const FETCH_SAVINGS_PLAN_DATA = 'FETCH_SAVINGS_PLAN_DATA';

// customer action types
export const FETCH_CUSTOMER_INVESTMENT = 'FETCH_CUSTOMER_INVESTMENT';
export const FETCH_CUSTOMER_TIMELINE_ORDERS = 'FETCH_CUSTOMER_TIMELINE_ORDERS';
export const FETCH_CUSTOMER_TIMELINE_FEES_TRANSACTIONS =
  'FETCH_CUSTOMER_TIMELINE_FEES_TRANSACTIONS';
export const FETCH_CUSTOMER_TIMELINE_REBALANCING_EVENTS =
  'FETCH_CUSTOMER_TIMELINE_REBALANCING_EVENTS';
export const FETCH_CUSTOMER_TIMELINE_DISTRIBUTION_EVENTS =
  'FETCH_CUSTOMER_TIMELINE_DISTRIBUTION_EVENTS';
export const FETCH_CUSTOMER_INVESTMENT_DOCUMENTS = 'FETCH_CUSTOMER_INVESTMENT_DOCUMENTS';
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_TAMS_TRANSACTION_ACCOUNT = 'FETCH_TAMS_TRANSACTION_ACCOUNT';
export const FETCH_TRANSACTION_ACCOUNT_TRANSACTIONS = 'FETCH_TRANSACTION_ACCOUNT_TRANSACTIONS';
export const FETCH_CUSTOMER_INVESTMENT_HISTORY = 'FETCH_CUSTOMER_INVESTMENT_HISTORY';
export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_INVESTMENTS = 'FETCH_CUSTOMER_INVESTMENTS';
export const FETCH_CUSTOMER_DEPOSIT = 'FETCH_CUSTOMER_DEPOSIT';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const POST_CUSTOMER_DOCUMENTS = 'POST_CUSTOMER_DOCUMENTS';
export const POST_CUSTOMER_CONTACT_DATA = 'POST_CUSTOMER_CONTACT_DATA';
export const POST_CUSTOMER_ADDRESS_CONFIRM = 'POST_CUSTOMER_ADDRESS_CONFIRM';
export const POST_CUSTOMER_PASSWORD = 'POST_CUSTOMER_PASSWORD';
export const POST_CUSTOMER_PASSWORD_CONFIRM = 'POST_CUSTOMER_PASSWORD_CONFIRM';
export const POST_CUSTOMER_MAILING_PREFERENCES = 'POST_CUSTOMER_MAILING_PREFERENCES';
export const SUBSCRIBE_TO_NEWSLETTER = 'SUBSCRIBE_TO_NEWSLETTER';
export const FETCH_MAIL_SUBSCRIPTION_DATA = 'FETCH_MAIL_SUBSCRIPTION_DATA';
export const FETCH_AVAILABLE_COUNTRIES = 'FETCH_AVAILABLE_COUNTRIES';
export const FETCH_MAILBOX_DOCUMENTS_DATA = 'FETCH_MAILBOX_DOCUMENTS_DATA';
export const FETCH_MAILBOX_DATA = 'FETCH_MAILBOX_DATA';
export const FETCH_MAILBOX_INBOX_DATA = 'FETCH_MAILBOX_INBOX_DATA';
export const FETCH_MAILBOX_SENT_DATA = 'FETCH_MAILBOX_SENT_DATA';
export const FETCH_MAILBOX_CURRENT_MESSAGE_DATA = 'FETCH_MAILBOX_CURRENT_MESSAGE_DATA';
export const FETCH_MAILBOX_UNREAD_MESSAGES_COUNT = 'FETCH_MAILBOX_UNREAD_MESSAGES_COUNT';
export const FETCH_CUSTOMER_TAX_EXEMPTION = 'FETCH_CUSTOMER_TAX_EXEMPTION';
export const POST_CUSTOMER_TAX_EXEMPTION = 'POST_CUSTOMER_TAX_EXEMPTION';
export const POST_REFERENCE_ACCOUNT_INITIAL = 'POST_REFERENCE_ACCOUNT_INITIAL';
export const POST_DISABLE_AUTO_PROLONGATION = 'POST_DISABLE_AUTO_PROLONGATION';
export const FETCH_IDENTIFICATION_INFO = 'FETCH_IDENTIFICATION_INFO';
export const POST_IDNOW_SEND_SMS = 'POST_IDNOW_SEND_SMS';
export const POST_IDNOW_PHONE_CHANGE = 'POST_IDNOW_PHONE_CHANGE';
export const POST_MAILBOX_DATA = 'POST_MAILBOX_DATA';
export const FETCH_CUSTOMER_BRANDING = 'FETCH_CUSTOMER_BRANDING';
export const FETCH_NATIONAL_IDENTIFIER = 'FETCH_NATIONAL_IDENTIFIER';
export const POST_NATIONAL_IDENTIFIER = 'POST_NATIONAL_IDENTIFIER';
export const POST_CUSTOMER_MTAN_BLOCK = 'POST_CUSTOMER_MTAN_BLOCK';
export const FETCH_CUSTOMER_PRODUCT_ACCESS = 'FETCH_CUSTOMER_PRODUCT_ACCESS';
export const POST_CUSTOMER_BLOCK_ACCOUNT = 'POST_CUSTOMER_BLOCK_ACCOUNT';
export const POST_APPLICATION_DOCUMENT = 'POST_APPLICATION_DOCUMENT';
export const FETCH_CUSTOMER_DATA_FOR_TOKEN = 'FETCH_CUSTOMER_DATA_FOR_TOKEN';
export const POST_PASSWORD_INITIAL = 'POST_PASSWORD_INITIAL';
export const FETCH_CUSTOMER_VALIDITY_STATUS = 'FETCH_CUSTOMER_VALIDITY_STATUS';
export const POST_CUSTOMER_VALIDITY_STATUS = 'POST_CUSTOMER_VALIDITY_STATUS';
export const TOGGLE_LOGIN_OTP_VERIFICATION = 'TOGGLE_OTP_VISIBILITY';
export const FETCH_CUSTOMER_PORTFOLIO_ORDERS = 'FETCH_CUSTOMER_PORTFOLIO_ORDERS';

// login related actions
export const FETCH_AUTH_CONFIGURATION = 'customer/FETCH_AUTH_CONFIGURATION';
export const FETCH_COGNITO_CREDENTIALS = 'FETCH_COGNITO_CREDENTIALS';
export const FETCH_KEYCLOAK_CREDENTIALS = 'FETCH_KEYCLOAK_CREDENTIALS';
export const POST_CUSTOMER_LOGIN_CREDENTIALS = 'POST_CUSTOMER_LOGIN_CREDENTIALS';
export const POST_CUSTOMER_LOGOUT_REQUEST = 'POST_CUSTOMER_LOGOUT_REQUEST';
export const SET_LOGIN_ERROR = 'customer/SET_LOGIN_ERROR';

// ui-actions
export const CHANGE_UI_STATE = 'CHANGE_UI_STATE';
export const RESET_ORDER_FORM_VALIDITY = 'RESET_ORDER_FORM_VALIDITY';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const FETCH_FEATURE_SWITCHES = 'FETCH_FEATURE_SWITCHES';
export const FETCH_CMS_PAGE_CONTENT = 'FETCH_CMS_PAGE_CONTENT';
export const CLEAR_NOTICES = 'CLEAR_NOTICES';

// deposits
export const FETCH_DEPOSIT_FOR_PROLONGATION = 'FETCH_DEPOSIT_FOR_PROLONGATION';
export const TRIGGER_VOICE_CALL = 'TRIGGER_VOICE_CALL';
export const FETCH_DEPOSITS_PRODUCTS = 'FETCH_DEPOSITS_PRODUCTS';
export const FETCH_DEPOSITS_PRODUCT = 'FETCH_DEPOSITS_PRODUCT';
export const RESET_DEPOSITS_PRODUCT = 'RESET_DEPOSITS_PRODUCT';
export const SET_DEPOSIT_DATA = 'SET_DEPOSIT_DATA';
export const FETCH_DEPOSIT_REVOKE_DATA = 'FETCH_DEPOSIT_REVOKE_DATA';
export const POST_DEPOSIT_REVOKE_DATA = 'POST_DEPOSIT_REVOKE_DATA';
export const RESET_DEPOSIT_REVOKE_DATA = 'RESET_DEPOSIT_REVOKE_DATA';
export const POST_DEPOSIT_ORDER_DATA = 'POST_DEPOSIT_ORDER_DATA';
export const POST_DEPOSIT_PROLONGATION_DATA = 'POST_DEPOSIT_PROLONGATION_DATA';
export const POST_DEPOSIT_PROLONGATION_MTAN = 'POST_DEPOSIT_PROLONGATION_MTAN';
export const POST_DEPOSIT_ORDER_MTAN = 'POST_DEPOSIT_ORDER_MTAN';
export const SET_OVERNIGHT_PAYIN_DATA = 'SET_OVERNIGHT_PAYIN_DATA';
export const POST_OVERNIGHT_PAYIN_DATA = 'POST_OVERNIGHT_PAYIN_DATA';
export const POST_OVERNIGHT_PAYIN_MTAN = 'POST_OVERNIGHT_PAYIN_MTAN';
export const RESET_OVERNIGHT_PAYIN_NOTICE = 'RESET_OVERNIGHT_PAYIN_NOTICE';
export const POST_OVERNIGHT_SAVINGS_PLAN_DATA = 'POST_OVERNIGHT_SAVINGS_PLAN_DATA';
export const POST_OVERNIGHT_SAVINGS_PLAN_MTAN = 'POST_OVERNIGHT_SAVINGS_PLAN_MTAN';
export const SET_OVERNIGHT_PAYOUT_DATA = 'SET_OVERNIGHT_PAYOUT_DATA';
export const RESET_OVERNIGHT_PAYOUT_NOTICE = 'RESET_OVERNIGHT_PAYOUT_NOTICE';
export const POST_OVERNIGHT_PAYOUT_DATA = 'POST_OVERNIGHT_PAYOUT_DATA';
export const POST_OVERNIGHT_PAYOUT_MTAN = 'POST_OVERNIGHT_PAYOUT_MTAN';
export const FETCH_OVERNIGHT_TRANSACTIONS = 'FETCH_OVERNIGHT_TRANSACTIONS';
export const FETCH_DEPOSITS_PRODUCTS_TOP_OFFERS = 'FETCH_DEPOSITS_PRODUCTS_TOP_OFFERS';
export const FETCH_EARLY_TERMINATION_DATA = 'FETCH_EARLY_TERMINATION_DATA';
export const POST_EARLY_TERMINATION_DATA = 'POST_EARLY_TERMINATION_DATA';
export const POST_EARLY_TERMINATION_MTAN = 'POST_EARLY_TERMINATION_MTAN';
export const RESET_EARLY_TERMINATION_FORM = 'RESET_EARLY_TERMINATION_FORM';
export const FETCH_DEPOSIT_ACCOUNT_APPLICATION_FORM = 'FETCH_DEPOSIT_ACCOUNT_APPLICATION_FORM';

// Dashboard
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const FETCH_PENSION_PRODUCTS_DASHBOARD_DATA = 'FETCH_PENSION_PRODUCTS_DASHBOARD_DATA';

// pension products
export const FETCH_PENSION_CUSTOMER_TOKEN = 'FETCH_PENSION_CUSTOMER_TOKEN';
export const POST_PENSION_CUSTOMER_SHARE_DATA = 'POST_PENSION_CUSTOMER_SHARE_DATA';
export const POST_PENSION_CUSTOMER_SHARE_SESSION = 'POST_PENSION_CUSTOMER_SHARE_SESSION';
export const CHANGE_PENSION_PRODUCT_TYPE = 'CHANGE_PENSION_PRODUCT_TYPE';
export const CHANGE_PP_TARGET_LINK = 'CHANGE_PP_TARGET_LINK';

// globals
export const POST_LOGOUT = 'POST_LOGOUT';
export const FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS';

// etf-c action types
export const SET_ETF_C_FUNDS_LIST = 'SET_ETF_C_FUNDS_LIST';
export const TOGGLE_ETF_C_FUNDS_OVERVIEW_FILTER_CHECKBOX =
  'TOGGLE_ETF_C_FUNDS_OVERVIEW_FILTER_CHECKBOX';
export const CHANGE_ETF_C_FUNDS_OVERVIEW_FILTER_SELECT =
  'CHANGE_ETF_C_FUNDS_OVERVIEW_FILTER_SELECT';
export const RESET_ETF_C_FUNDS_OVERVIEW_FILTER_SELECT = 'RESET_ETF_C_FUNDS_OVERVIEW_FILTER_SELECT';
export const CHANGE_ETF_C_FUNDS_OVERVIEW_FILTER_TOGGLE =
  'CHANGE_ETF_C_FUNDS_OVERVIEW_FILTER_TOGGLE';
export const SET_ETF_C_FUNDS_OVERVIEW_SEARCH_VALUE = 'SET_ETF_C_FUNDS_OVERVIEW_SEARCH_VALUE';

// PIA
export const PIA_FETCH_PRODUCTS_INSTANCES = 'PIA_FETCH_PRODUCTS_INSTANCES';
export const PIA_FETCH_PRODUCT_INSTANCE_DETAILS = 'PIA_FETCH_PRODUCT_INSTANCE_DETAILS';

// POM
export const POM_FETCH_CUSTOMER_ORDERS = 'POM_FETCH_CUSTOMER_ORDERS';
export const POM_FETCH_PORTFOLIO_ORDERS = 'POM_FETCH_PORTFOLIO_ORDERS';
export const POM_FETCH_ETFC_CONSENT_ORDERS = 'POM_FETCH_ETFC_CONSENT_ORDERS';
export const POM_FETCH_CRYPTO_CONSENT_ORDERS = 'POM_FETCH_CRYPTO_CONSENT_ORDERS';
export const POM_POST_ETFC_DRAFT_ORDER = 'POM_POST_ETFC_DRAFT_ORDER';
export const POM_POST_ETFC_VERIFY_ORDER = 'POM_POST_ETFC_VERIFY_ORDER';
export const POM_POST_CRYPTO_DRAFT_ORDER = 'POM_POST_CRYPTO_DRAFT_ORDER';
export const POM_POST_CRYPTO_VERIFY_ORDER = 'POM_POST_CRYPTO_VERIFY_ORDER';
export const POM_FETCH_PRODUCTS = 'POM_FETCH_PRODUCTS';
export const POM_FETCH_PRODUCTS_ACCESS = 'POM_FETCH_PRODUCTS_ACCESS';

// DBS
export const DBS_FETCH_CUSTOMER_DEPOSITS = 'DBS_FETCH_CUSTOMER_DEPOSITS';
export const DBS_MERGE_CUSTOMER_DEPOSITS_WITH_DTS = 'DBS_MERGE_CUSTOMER_DEPOSITS_WITH_DTS';
export const DBS_FETCH_DEPOSIT = 'DBS_FETCH_DEPOSIT';
export const FETCH_DEPOSIT_STATUS = 'FETCH_DEPOSIT_STATUS';
export const FETCH_DBS_PRODUCTS_ACCESS = 'FETCH_DBS_PRODUCTS_ACCESS';
export const FETCH_DBS_PRODUCTS_TYPES = 'FETCH_DBS_PRODUCTS_TYPES';
export const POST_DISABLE_AUTO_RENEWAL = 'POST_DISABLE_AUTO_RENEWAL';

// DAS
export const DAS_FETCH_CUSTOMER_DEPOSITS = 'DAS_FETCH_CUSTOMER_DEPOSITS';
export const DAS_FETCH_DEPOSITS_BALANCE = 'DAS_FETCH_DEPOSITS_BALANCE';
export const DAS_FETCH_DEPOSIT = 'DAS_FETCH_DEPOSIT';
export const DAS_FETCH_DEPOSIT_TRANSACTIONS = 'DAS_FETCH_DEPOSIT_TRANSACTIONS';

// DTS
export const DTS_FETCH_DEPOSITS_TAX_REQUIREMENTS = 'DTS_FETCH_DEPOSITS_TAX_REQUIREMENTS';

// CAS
export const FETCH_CAS_CUSTOMER_DATA = 'FETCH_CAS_CUSTOMER_DATA';

// KCI
export const FETCH_KCI_CUSTOMER_KYC_STATUS = 'FETCH_KCI_CUSTOMER_KYC_STATUS';

// KSS
export const FETCH_KSS_CUSTOMER_KYC_STATUS = 'FETCH_KSS_CUSTOMER_KYC_STATUS';

// DDOC
export const FETCH_DDOC_DOCUMENT_REQUIREMENTS = 'FETCH_DDOC_DOCUMENT_REQUIREMENTS';
