import { handle } from 'redux-pack';
import { weltInvestIdPattern, justEtfIdPattern } from 'utils/IPUtils';
import { saveLocaleInLocalStorage } from 'utils/Locale';
import { initialState } from '../selectors';
import * as types from '../../actionTypes';

export default (state = initialState, action) => {
  const { type, payload = {} } = action;
  let newState = state;

  const handleFailure = (prevState) => {
    const stateObj = {
      ...prevState,
    };

    if (
      payload.response.status &&
      payload.response.status === 403 &&
      payload.response.data &&
      payload.response.data.title_i18n
    ) {
      stateObj.requestError = {
        status: payload.response.status,
        statusText: payload.response.statusText,
        title_i18n: payload.response.data.title_i18n,
      };
    } else {
      stateObj.requestError = {
        ...prevState.requestError,
        status: payload.response.status,
        statusText: payload.response.statusText,
      };
    }

    return stateObj;
  };

  switch (true) {
    case type && (type.includes('POST_') || type.includes('UPDATE_') || type.includes('FETCH_')):
      newState = handle(state, action, {
        start: (prevState) => ({ ...prevState, isPosting: true }),
        finish: (prevState) => ({ ...prevState, isPosting: false }),
      });
      break;
    case type && type.includes('_TIMELINE_'):
      newState = handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoadingCustomerTimeline: true }),
        finish: (prevState) => ({ ...prevState, isLoadingCustomerTimeline: false }),
      });
      break;
    case type && type === types.FETCH_PORTFOLIOS:
      newState = handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            hasFetchedAllWeltInvestPortfolios: !!Object.keys(payload).find((id) =>
              id.match(weltInvestIdPattern),
            ),
            hasFetchedAllJustEtfPortfolios: !!Object.keys(payload).find((id) =>
              id.match(justEtfIdPattern),
            ),
          };
        },
      });
      break;
    case type && type === types.FETCH_CUSTOMER_INVESTMENT_DOCUMENTS:
      newState = handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoadingDocumentsTimeline: true }),
        finish: (prevState) => ({ ...prevState, isLoadingDocumentsTimeline: false }),
      });
      break;

    default:
      break;
  }

  switch (type) {
    case types.FETCH_CUSTOMER_DATA:
      return handle(newState, action, {
        success: (prevState) => ({
          ...prevState,
          hasTaxId: !!payload.data.tax_id_number,
        }),
        failure: (prevState) => ({
          ...prevState,
          requestError: {
            status: payload.response.status,
            statusText: payload.response.statusText,
          },
        }),
      });
    case types.RESET_ORDER_FORM_VALIDITY:
      return {
        ...state,
        orderFormValidity: initialState.orderFormValidity,
      };
    case types.FETCH_PORTFOLIOS:
    case types.FETCH_PORTFOLIO:
    case types.TRIGGER_VOICE_CALL:
    case types.FETCH_ACCOUNTS:
    case types.FETCH_TAMS_TRANSACTION_ACCOUNT:
    case types.FETCH_DEPOSIT_REVOKE_DATA:
    case types.FETCH_SAVINGS_PLAN_DATA:
    case types.FETCH_CUSTOMER_TIMELINE_ORDERS:
    case types.FETCH_CUSTOMER_TIMELINE_FEES_TRANSACTIONS:
    case types.FETCH_CUSTOMER_TIMELINE_REBALANCING_EVENTS:
    case types.FETCH_CUSTOMER_TIMELINE_DISTRIBUTION_EVENTS:
    case types.FETCH_CUSTOMER_INVESTMENT_DOCUMENTS:
    case types.FETCH_TRANSACTION_ACCOUNT_TRANSACTIONS:
    case types.FETCH_CUSTOMER_INVESTMENT_HISTORY:
    case types.FETCH_CUSTOMER_DEPOSIT:
    case types.FETCH_AVAILABLE_COUNTRIES:
    case types.FETCH_MAILBOX_DOCUMENTS_DATA:
    case types.FETCH_MAILBOX_DATA:
    case types.FETCH_MAILBOX_INBOX_DATA:
    case types.FETCH_MAILBOX_SENT_DATA:
    case types.FETCH_MAILBOX_CURRENT_MESSAGE_DATA:
    case types.FETCH_CUSTOMER_TAX_EXEMPTION:
    case types.FETCH_CUSTOMER_BRANDING:
    case types.FETCH_NATIONAL_IDENTIFIER:
    case types.FETCH_CUSTOMER_PRODUCT_ACCESS:
    case types.FETCH_CMS_PAGE_CONTENT:
    case types.FETCH_DEPOSITS_PRODUCTS:
    case types.FETCH_DEPOSITS_PRODUCT:
    case types.FETCH_OVERNIGHT_TRANSACTIONS:
    case types.FETCH_DEPOSITS_PRODUCTS_TOP_OFFERS:
    case types.FETCH_DEPOSIT_FOR_PROLONGATION:
    case types.FETCH_EARLY_TERMINATION_DATA:
      return handle(newState, action, {
        failure: (prevState) => handleFailure(prevState),
      });
    case types.FETCH_DASHBOARD_DATA:
      return handle(newState, action, {
        failure: (prevState) => handleFailure(prevState),
        success: (prevState) => ({
          ...prevState,
          notices: {
            ...payload.data.notices,
          },
        }),
      });

    case types.FETCH_CUSTOMER_INVESTMENTS:
      return handle(newState, action, {
        failure: (prevState) => handleFailure(prevState),
        success: (prevState) => {
          return {
            ...prevState,
            notices: {
              ...prevState.notices,
              ...payload.data.term_deposits.notices,
            },
          };
        },
      });

    case types.POST_CUSTOMER_LOGIN_CREDENTIALS:
    case types.FETCH_CUSTOMER_DATA_FOR_TOKEN:
      return handle(newState, action, {
        start: (prevState) => ({
          ...prevState,
          notices: {},
          requestError: null,
        }),
        failure: (prevState) => {
          let requestError = null;

          if (payload.response.status && payload.response.status === 403) {
            requestError = {
              status: payload.response.status,
              statusText: payload.response.statusText,
              title_i18n: payload.response.data.title_i18n,
            };
          } else {
            requestError = {
              status: payload.response.status,
              statusText: payload.response.statusText,
            };
          }

          return {
            ...prevState,
            requestError,
          };
        },
        success: (prevState) => ({
          ...prevState,
          requestError: null,
        }),
      });
    case types.FETCH_AUTH_CONFIGURATION:
      return handle(newState, action, {
        start: (prevState) => ({
          ...prevState,
        }),
        finish: (prevState) => ({ ...prevState, isPosting: true }),
      });
    case types.FETCH_CUSTOMER_INVESTMENT:
      return handle(newState, action, {
        success: (prevState) => ({
          ...prevState,
          notices: {
            ...payload.data.notices,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          requestError: {
            status: payload.response.status,
            statusText: payload.response.statusText,
          },
        }),
      });
    case types.POST_OVERNIGHT_PAYIN_MTAN:
    case types.POST_OVERNIGHT_SAVINGS_PLAN_MTAN:
    case types.POST_OVERNIGHT_PAYOUT_MTAN:
    case types.POST_DISABLE_AUTO_PROLONGATION:
    case types.POST_DEPOSIT_ORDER_MTAN:
    case types.POST_CUSTOMER_PASSWORD:
    case types.POST_CUSTOMER_PASSWORD_CONFIRM:
    case types.POST_EARLY_TERMINATION_MTAN:
    case types.POST_EARLY_TERMINATION_DATA:
      return handle(newState, action, {
        success: (prevState) => ({
          ...prevState,
          notices: {
            ...payload.data.notices,
          },
        }),
      });
    case types.POST_DISABLE_AUTO_RENEWAL:
      return handle(newState, action, {
        success: (prevState) => {
          const notices = {
            'deposit.autorenewal.disabled': payload.data?.deposit_id,
          };

          return {
            ...prevState,
            notices: {
              ...notices,
            },
          };
        },
      });
    case types.CHANGE_UI_STATE:
      return {
        ...newState,
        ...payload,
      };
    case types.FETCH_FEATURE_SWITCHES:
      return handle(state, action, {
        start: (prevState) => {
          return {
            ...prevState,
            features: [],
            areFeaturesLoaded: false,
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            features: payload.data.reduce(
              (ftr, key) => {
                // eslint-disable-next-line no-param-reassign
                ftr[key] = true;

                return ftr;
              },
              {
                ...prevState.features,
              },
            ),
            areFeaturesLoaded: true,
          };
        },
      });
    case types.CHANGE_LOCALE:
      saveLocaleInLocalStorage(payload);

      return {
        ...newState,
        locale: payload,
      };
    case types.CLEAR_NOTICES: {
      const cleanNotices = Object.keys(state.notices).reduce((acc, n) => {
        if (!payload.includes(n)) acc[n] = state.notices[n];

        return acc;
      }, {});

      return { ...state, notices: cleanNotices };
    }
    case types.FETCH_IDENTIFICATION_INFO:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            identificationMobileNumber: payload.data.mobile_number.replace(/[^0-9+]/g, ''),
          };
        },
      });
    case types.POST_LOGOUT:
      return handle(state, action, {
        failure: (prevState) => ({
          ...prevState,
          notices: {},
          requestError: null,
        }),
        success: () => ({
          ...initialState,
          notices: {},
          requestError: null,
        }),
      });
    default:
      return newState;
  }
};
